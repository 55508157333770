import { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ApiUrlContext } from "../GlobalContext/ApiUrlContext";
import MyLoader from "./MyLoader";
import MyNavBar from "../Components/MyNavBar";
import MyImageCarousel from "./MyImageCarousel";
import { Rating } from "@smastrom/react-rating";
import SimpleRelatedItemsTable from "./SimpleRelatedItemsTable";
import ChartPriceHistory from "./ChartPriceHistory";
import CustomerReviews from "./CustomerReviews";

//import PreviousLocationContext from "../GlobalContext/PreviousLocationContext";

function SingleViewItem({ jsonUrl, setPageTitle, updateBaseUrl, setTeaserImage, productType }) {

    const { itemDetailId } = useParams();
    const apiUrl = useContext(ApiUrlContext);
    const [initialRender, setInitialRender] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [item, setItem] = useState([]);
    const [relatedItemsByBrand, setRelatedItemsByBrand] = useState([]);
    const [relatedItemsByPrice, setRelatedItemsByPrice] = useState([]);
    const navigate = useNavigate();

    //const prevLocation = useContext(PreviousLocationContext);
    //console.log('Previous location was', prevLocation);

    useEffect(() => {
        if (initialRender) {
            setInitialRender(false);
        } else {
            //const urltemp = apiUrl + 'items/itemDetailJson.json?id=' + itemDetailId + '&lang=de';
            const urltemp = apiUrl + jsonUrl + '?id=' + itemDetailId + '&lang=de';
            setIsLoading(true);
            fetch(urltemp, {
                method: 'GET'
            })
                //.then(response => response.json())
                .then(response => {
                    console.log('response', response);
                    if (response.ok) {
                        return response.json();
                    } else {
                        // If response status is not ok (i.e., not 200), throw an error
                        throw new Error('Network response was not ok');
                    }
                })
                .then((result) => {
                    console.log('item', result.result.item);
                    if (undefined === result.result.item) {
                        // redirect to PageNotFound
                        return navigate("/404-notfound");
                        //window.location.href = '/404-notfound';
                    }
                    setItem(result.result.item);
                    setRelatedItemsByBrand(result.result.related_items_brand);
                    setRelatedItemsByPrice(result.result.related_items_price_range);
                    setIsLoading(false);

                    // call updateTitle function on parent component
                    setPageTitle(result.result.item.title);
                    updateBaseUrl(result.result.item.base_url);
                    setTeaserImage(result.result.item.image);

                })
                .catch((error) => {
                    console.error('Error fetching dataFiles:', error);
                    setIsLoading(false);
                    return navigate("/404-notfound");

                    //var metaTag = document.createElement('meta');
                    //metaTag.setAttribute('http-equiv', 'refresh');
                    //metaTag.setAttribute('content', '0; URL=/404-notfound');
                    //document.head.appendChild(metaTag);
                });
        }
    }, [initialRender, apiUrl, itemDetailId, jsonUrl, navigate, setPageTitle, updateBaseUrl, setTeaserImage]);

    //useEffect(() => {
    //    document.title = item.title;
    //}, [item.title]);

    return (
        <div className="single-view">
            <MyNavBar />
            <MyLoader isLoading={isLoading} />

            <div className="container mt-5 mb-2">
                <div className="row ">
                    <div className="col-lg-10 bg-lightxx text-black">
                        <div className="sidebar-header pb-4">
                            <h1 className='h4 main-color'>
                                {item.title}
                            </h1>
                            {(item.description !== "") && <p>{item.description}</p>}
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mb-5 slide-container">
                <div className="row ">
                    <div className="col-lg-4">
                        <MyImageCarousel images={item.images} />
                    </div>

                    <div className="col-lg-7 mt-5 mt-lg-0">
                        <h2 className="h4">Features:</h2>
                        <ul className="mt-2">
                            {item.feature_bullets && Object.keys(item.feature_bullets).map((key, idx) => (
                                <li key={idx}>{item.feature_bullets[key]}</li>
                            ))}
                        </ul>

                        {item.highlighted_keywords_filtered && item.highlighted_keywords_filtered.length > 0 &&
                            <div className="mt-4 mb-4 feature-pills">
                                {/*<h4>Sonstiges</h4>*/}
                                {Object.keys(item.highlighted_keywords_filtered).map((key, idx) => (
                                    <span key={idx} className="badge rounded-pill bg-secondary">
                                        {item.highlighted_keywords_filtered[key]}
                                    </span>
                                ))}
                            </div>
                        }

                        {item.customization_style && Object.keys(item.customization_style).length > 0 &&
                            <>
                                <h4>Größenoptionen</h4>
                                <ul className="mt-2 list-inline list-divider">
                                    {item.customization_size && Object.keys(item.customization_size).map((key, idx) => (
                                        <li className="list-inline-item" key={idx}>
                                            <a href={item.url} target="_blank" rel="noreferrer">{item.customization_size[key].value}</a>
                                        </li>
                                    ))}
                                    <li className="list-inline-item">(*)</li>
                                </ul>

                            </>
                        }

                        {item.customization_style && Object.keys(item.customization_style).length > 0 &&
                            <>
                                <h4>Variationen</h4>
                                <ul className="mt-2 list-inline list-divider">
                                    {Object.keys(item.customization_style).map((key, idx) => (
                                        <li className="list-inline-item" key={idx}>
                                            <a href={item.url} target="_blank" rel="noreferrer">{item.customization_style[key].value}</a>
                                        </li>
                                    ))}
                                    <li className="list-inline-item">(*)</li>
                                </ul>
                            </>
                        }

                        <div className="mt-3">
                            {item.number_of_people_bought &&
                                <div className="mt-1">Verkäufe: <strong>{item.number_of_people_bought}</strong></div>
                            }
                            {item.price &&
                                <div className="mt-1">Preis: <strong>{item.price}</strong></div>
                            }
                        </div>

                        <div className="mt-1">
                            <div className="float-start pe-2">Kundenrezensionen: &nbsp;
                                <a href={item.reviews_url} className='underline' target="_blank" rel="noreferrer" title={"Externer Link zu den Bewertungen für " + item.title}>
                                    <strong>{item.total_reviews}</strong>
                                </a>
                            </div>
                            <div className="float pt-0">
                                <Rating style={{ maxWidth: 90 }} value={item.stars} readOnly={true} />
                            </div>
                        </div>
                        <div className="mt-4">

                            Aktuellen Preis prüfen auf
                            <strong>
                                &nbsp;<a href={item.url} target="_blank" rel="noreferrer">amazon.de</a>
                            </strong>*
                            <br />(<span className="small">Zuletzt aktualisiert am {item.last_updated}</span>)&nbsp;
                        </div>

                        {/*
                        <div className="mt-5">
                            <span className="small"> Letztes Update: {item.last_updated}</span>
                        </div>
                        */}

                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-lg-12">
                        {item.price_history && item.price_history.length > 3 &&
                            <div className="mt-5">
                                <ChartPriceHistory dataPriceHistory={item.price_history} />
                            </div>
                        }
                        {item.customer_reviews && item.customer_reviews.length > 0 &&
                            <div className="mt-5">
                                <CustomerReviews customerReviews={item.customer_reviews} />
                            </div>
                        }
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-lg-12">
                        <div className="mt-0">
                            Mehr auf
                            <strong>
                                &nbsp;<a href={item.url} target="_blank" rel="noreferrer">amazon.de</a>(*)
                            </strong>
                        </div>
                        <div className="mt-4">
                            &lt;- &nbsp;
                            <Link to="/" className="">Zurück zur Übersicht</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container pb-3 mb-3">
                <hr />
            </div>

            <div className="container">
                <div className="row mt-6">
                    <div className="col-lg-6">
                        <h3 className="h6 main-color"><strong>Mehr in dieser Preiskaregorie (ca. +/- 30%)</strong></h3>
                        <SimpleRelatedItemsTable items={relatedItemsByPrice} productType={productType} />
                    </div>
                    <div className="col-lg-6 mt-5 mt-lg-0">
                        <h3 className="h6 main-color"><strong>Mehr von {item.brand}</strong></h3>
                        <SimpleRelatedItemsTable items={relatedItemsByBrand} productType={productType} />
                    </div>
                </div>
            </div>
        </div >
    )
};

export default SingleViewItem;