import { Rating } from "@smastrom/react-rating";
import Table from "react-bootstrap/esm/Table";
import { Link } from "react-router-dom";
function SimplePhoneTable({ items, productType }) {
    console.log('productType', productType);

    let detailUrlPrefix = 'viewdrive';
    if (productType === 'phone') {
        detailUrlPrefix = 'viewphone';
    }
    return (
        <Table hover responsive className={'result-table'}>
            <tbody>
                {items && items.map((item, idx) => (
                    <tr className="" key={idx}>
                        <td>
                            <a href={item.reviews_url} className='underline' target="_blank" rel="noreferrer" title={"Externer Link zu den Bewertungen für " + item.title}>
                                <div className="me-2">
                                    <img src={item.image}
                                        style={{ maxWidth: '100px', maxHeight: '120px' }}
                                        className=""
                                        alt="..." />
                                </div>
                            </a>
                        </td>
                        <td>
                            <strong>{item.title}</strong> <br />

                            <div className="mt-1">
                                <div className="float-start pe-2">Kundenrezensionen: &nbsp;
                                    <a href={item.reviews_url} className='underline' target="_blank" rel="noreferrer" title={"Externer Link zu den Bewertungen für " + item.title}>
                                        <strong>{item.total_reviews}</strong>
                                    </a>
                                </div>
                                <div className="float pt-0">
                                    <Rating style={{ maxWidth: 90 }} value={item.stars} readOnly={true} />
                                </div>
                            </div>
                            <div className="mt-1">
                                Preis: <strong>{item.price_string}</strong><br />
                                {/*<Link className='font-weight-normal' to={'/' + detailUrlPrefix + '/' + item.id}>Mehr ...</Link><br />*/}
                                <a href={item.url} target="_blank" rel="noreferrer"> <strong>Mehr Details und aktueller Preis auf amazon.de</strong></a> (*)
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

export default SimplePhoneTable;